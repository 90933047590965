import { callApi, callApiFile, callApiFileStorage } from './service'
import swal from "sweetalert"


export const audienceService = {
    getPending,
    getComplete,
    updateComplete,
    getAudiceImageById,
    getLastOrDefault
};


async function getPending() {
    return callApi('/backend/Auditorium/Pending', 'GET').then((result) => {

        if (result.responseCode == '00') {
            return result.data;
        } else {
            swal({ text: result.responseMessage, icon: "error" })
            return null;
        }

    });
}

async function getComplete() {
    return callApi('/backend/Auditorium/Complete', 'GET').then((result) => {

        if (result.responseCode == '00') {
            return result.data;
        } else {
            swal({ text: result.responseMessage, icon: "error" })
            return null;
        }

    });
}

async function updateComplete(date, id, elements) {
    return callApi('/backend/Auditorium/Complete/' + elements + '/' + date + '/' + id, 'GET').then((result) => {

        if (result.responseCode == '00') {
            return result.data;
        } else {
            swal({ text: result.responseMessage, icon: "error" })
            return null;
        }

    });
}

async function getLastOrDefault(date, id) {
    return callApi(`/backend/Auditorium/Complete/LastOrDefault/${date}/${id}`, 'GET').then((result) => {

        if (result.responseCode == '00') {
            return result.data;
        } else {
            swal({ text: result.responseMessage, icon: "error" })
            return null;
        }

    });
}

async function getAudiceImageById(auditoriumId){
    return callApi('/backend/Auditorium/WithImages/' + auditoriumId, 'GET').then((result) => handleRsponse(result));
}

function handleRsponse(result) {
    if (result.responseCode === '00')
        return result.data;
    else {
        return null;
    }
}